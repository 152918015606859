import { ThemeProvider } from "@material-ui/styles";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Entry } from "./src/entry";
import { theme } from "./src/styles/theme";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Entry />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

document.ontouchmove = function (event) {
  event.preventDefault();
};
