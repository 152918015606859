import Auth from "@aws-amplify/auth";
import { Grid } from "@material-ui/core";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import SpotifyWebPlayer from "./components/spotify/player";
import { Greeting } from "./components/greetings";
import { SpotifyLogin } from "./components/spotify/login";
import { Weather } from "./components/weather";

// const useStyles = makeStyles((theme) => ({
//   title: {
//     color: "white",
//   },
//   subTitle: {
//     color: "white",
//   },
// }));

function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const HomeScreen = () => {
  let query = useQuery();
  const [code, setCode] = useState<string>();
  const [token, setToken] = useState<string>();

  useEffect(() => {
    const searchCode = query.get("code");
    setCode(searchCode ?? undefined);
    query.delete("code");
  }, [query]);

  useEffect(() => {
    async function FetchToken() {
      const user = await Auth.currentAuthenticatedUser();
      const response = await fetch(
        `https://api.pasquillclough.com/spotify/token?code=${code}`,
        {
          method: "get",
          headers: {
            Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
          },
        }
      )
        .then((res) => res.json())
        .catch((err) => {
          console.log(err);
          return undefined;
        });

      setToken(response.data.access_token);
    }

    FetchToken();
  }, [code]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6} lg={8}>
        <Greeting />
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Weather />
        <div
          style={{
            marginTop: 20,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {token ? (
            <div
              style={{
                width: "75%",
                height: "260px",
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <SpotifyWebPlayer
                name="Dashboard Home"
                persistDeviceSelection={true}
                token={token}
                showSaveIcon
                syncExternalDevice
                styles={{
                  activeColor: "#1cb954",
                  bgColor: "#000",
                  color: "#fff",
                  loaderColor: "#fff",
                  sliderColor: "#1cb954",
                  sliderHandleColor: "#1cb954",
                  trackArtistColor: "#ccc",
                  trackNameColor: "#fff",
                }}
              />
            </div>
          ) : (
            <SpotifyLogin />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
