import { makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
  },
  subTitle: {
    color: "rgba(255,255,255,0.6)",
  },
}));

export const Clock = () => {
  const [date, setDate] = useState(new Date());
  const classes = useStyles();

  function refreshClock() {
    setDate(new Date());
  }
  useEffect(() => {
    const timerId = setInterval(refreshClock, 1000);
    return function cleanup() {
      clearInterval(timerId);
    };
  }, []);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "flex-end",
        justifyContent: "flex-end",
        flexDirection: "column",
      }}
    >
      <Typography className={classes.title} variant="h3">
        {date.toLocaleTimeString("en-GB")}
      </Typography>
      <Typography className={classes.subTitle} variant="caption">
        {format(new Date(), "EEEE, do MMMM yyyy")}
      </Typography>
    </div>
  );
};
