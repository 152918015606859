import { createTheme } from "@material-ui/core";

export const theme = createTheme({
  typography: {
    fontFamily: ['"Open Sans"', "sans-serif"].join(","),
  },
  palette: {
    type: "dark",
  },
});
