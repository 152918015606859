import { IconButton, Typography } from "@material-ui/core";
import { PropsWithChildren } from "react";
import { Auth } from "aws-amplify";
import { Home, LogOut, Menu } from "react-feather";
import { useHistory } from "react-router";

export const Container = (props: PropsWithChildren<{}>) => {
  const history = useHistory();

  const logout = async () => {
    return Auth.signOut({ global: true });
  };
  return (
    <>
      <main style={{ height: "100vh", overflow: "hidden" }}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              height: "100vh",
              width: "100px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <MenuButton
                onClick={() => history.push("/")}
                title="Home"
                icon={Home}
              />
              <MenuButton
                onClick={() => history.push("/rooms")}
                title="Rooms"
                icon={Menu}
              />
              <MenuButton onClick={logout} title="Logout" icon={LogOut} />
            </div>
          </div>
          <div
            style={{
              width: "calc(100vw - 130px)",
              marginTop: 10,
              height: "calc(100vh - 10px)",
            }}
          >
            {props.children}
          </div>
        </div>
      </main>
    </>
  );
};

const MenuButton = (props: { onClick: any; icon: any; title: string }) => {
  const Icon = props.icon;
  return (
    <div
      style={{
        marginTop: 15,
        marginBottom: 15,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton onClick={props.onClick}>
        <Icon />
      </IconButton>
      <Typography variant="caption" style={{ color: "white" }}>
        {props.title}
      </Typography>
    </div>
  );
};
