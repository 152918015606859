import NoSleep from "nosleep.js";
import { useEffect, useMemo, useState } from "react";
export var useNoSleep = function (enabled) {
    var _a = useState(false), alreadyEnabled = _a[0], setAlreadyEnabled = _a[1];
    var noSleep = useMemo(function () { return new NoSleep(); }, []);
    useEffect(function () {
        if (alreadyEnabled === enabled) {
            return;
        }
        if (enabled) {
            noSleep.enable();
        }
        else {
            noSleep.disable();
        }
        setAlreadyEnabled(enabled);
    }, [alreadyEnabled, enabled, noSleep]);
};
export default useNoSleep;
