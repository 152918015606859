import Auth from "@aws-amplify/auth";
import {
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { ForecastResponse, WeatherResponse } from "./weather.types";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 15,
    borderRadius: 15,
  },
  title: {
    color: "white",
  },
}));

export const Weather = () => {
  const classes = useStyles();

  const [weather, setWeather] = useState<WeatherResponse>();
  const [forecast, setForecast] = useState<ForecastResponse>();

  useEffect(() => {
    async function FetchWeather() {
      const user = await Auth.currentAuthenticatedUser();

      const response = await fetch("https://api.pasquillclough.com/weather", {
        method: "get",
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          console.log(err);
          return undefined;
        });

      if (!response) {
        return;
      }

      setWeather(response);
      return;
    }

    FetchWeather();
  }, []);

  useEffect(() => {
    async function FetchForecast() {
      const user = await Auth.currentAuthenticatedUser();

      const response = await fetch("https://api.pasquillclough.com/forecast", {
        method: "get",
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      })
        .then((res) => res.json())
        .catch((err) => {
          console.log(err);
          return undefined;
        });

      if (!response) {
        return;
      }

      setForecast(response);
      return;
    }

    FetchForecast();
  }, []);

  if (!weather || !forecast) {
    return (
      <div className={classes.container}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <CircularProgress style={{ color: "white" }} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.container}>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" style={{ color: "white", marginRight: 15 }}>
          {weather.weather.temp_c}&#176;
        </Typography>
        <img
          src={`https:${weather.weather.condition.icon}`}
          alt={weather.weather.condition.text}
          style={{ height: 120, width: 120 }}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Typography
          variant="body1"
          style={{
            color: "rgba(255,255,255,0.6)",
            width: "75%",
            textAlign: "right",
          }}
        >
          Today it's {forecast.forecast.day.condition.text.toLowerCase()} but
          currently it's {weather.weather.condition.text.toLowerCase()}.
        </Typography>
      </div>
      <div style={{ marginTop: 40, width: "100%" }}>
        <Grid container spacing={3}>
          <Vital
            title="Average Temp"
            value={forecast.forecast.day.avgtemp_c.toString()}
            isTemp
          />
          <Vital
            title="Rain Chance"
            value={`${forecast.forecast.day.daily_chance_of_rain.toString()}%`}
          />
          <Vital
            title="Sunrise"
            value={forecast.forecast.astro.sunrise.toString()}
          />
          <Vital
            title="Sunset"
            value={forecast.forecast.astro.sunset.toString()}
          />
        </Grid>
      </div>
    </div>
  );
};

const Vital = (props: { title: string; value: string; isTemp?: boolean }) => (
  <Grid item xs={6}>
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "center",
        height: "100%",
        flexDirection: "column",
      }}
    >
      {props.isTemp ? (
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "center" }}
        >
          {props.value}&#176;
        </Typography>
      ) : (
        <Typography
          variant="h5"
          style={{ color: "white", textAlign: "center" }}
        >
          {props.value}
        </Typography>
      )}
      <Typography
        variant="caption"
        style={{ color: "rgba(255,255,255,0.6)", textAlign: "center" }}
      >
        {props.title}
      </Typography>
    </div>
  </Grid>
);
