import { Grid, makeStyles, Typography } from "@material-ui/core";
import { Clock } from "./clock";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "white",
  },
  subTitle: {
    color: "rgba(255,255,255,0.6)",
  },
  container: {
    marginTop: 100,
    padding: 15,
    display: "flex",
    alignItems: "flex-end",
  },
}));

const greeting = () => {
  const date = new Date();
  let hours = date.getHours();
  console.log(hours);
  let status =
    hours < 12
      ? "Good Morning"
      : hours < 18 && hours >= 12
      ? "Good Afternoon"
      : hours < 21
      ? "Good Evening"
      : "Goodnight";
  return status;
};

export const Greeting = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              height: "calc(100% - 20px)",
            }}
          >
            <Typography className={classes.title} variant="h4">
              {greeting()}
            </Typography>
            <Typography className={classes.subTitle} variant="body1">
              68 Clarendon Gardens
            </Typography>
          </div>
        </Grid>
        <Grid xs={12} md={6}>
          <div
            style={{
              height: "calc(100% - 20px)",
              width: "100%",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
              flexDirection: "column",
            }}
          >
            <Clock />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
