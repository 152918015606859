import { Route, Switch } from "react-router-dom";
import { Container } from "../components/Container";
import { HomeScreen } from "../screens/home";
import useNoSleep from "use-no-sleep";
import { useState, useEffect } from "react";

export const Routes = () => {
  const [noSleep, setNoSleep] = useState(false);

  useEffect(() => {
    const handleClick = () => setNoSleep(true);

    document.addEventListener("click", handleClick);

    return document.removeEventListener("click", handleClick);
  }, []);

  useEffect(() => {
    const handleClick = () => setNoSleep(true);

    document.addEventListener("touchstart", handleClick);

    return document.removeEventListener("touchstart", handleClick);
  }, []);

  useNoSleep(noSleep);

  return (
    <Container>
      <Switch>
        <Route exact path="/">
          <HomeScreen />
        </Route>
      </Switch>
    </Container>
  );
};
