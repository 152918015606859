import Auth from "@aws-amplify/auth";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import { useState } from "react";

export const SpotifyLogin = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const response = await fetch(
      "https://api.pasquillclough.com/spotify/authorise",
      {
        method: "get",
        headers: {
          Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`,
        },
      }
    )
      .then((res) => res.json())
      .catch((err) => {
        console.log(err);
        return undefined;
      });

    console.log(response);

    if (!response) {
      setError(true);
      setLoading(false);
      return;
    }

    const redirectUrl = response.data;

    window.location.href = redirectUrl;
    setLoading(true);
  };

  return (
    <div>
      <Button
        onClick={handleLogin}
        style={{ backgroundColor: "white", color: "black" }}
      >
        {loading ? (
          <CircularProgress style={{ color: "black" }} />
        ) : (
          <>
            <Typography variant="body1">Login with Spotify</Typography>
          </>
        )}
      </Button>
      {error && (
        <Typography variant="body1" style={{ color: "rgba(255,255,255,0.8)" }}>
          Something went wrong.
        </Typography>
      )}
    </div>
  );
};
