import { useEffect, useState } from "react";
import Amplify from "aws-amplify";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Routes } from "../routes";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";

const history = createBrowserHistory();

Amplify.configure({
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.REACT_APP_AUTH_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.REACT_APP_AUTH_USERPOOL,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.REACT_APP_AUTH_CLIENT,
  },
});

export function Entry() {
  const [authState, setAuthState] = useState<AuthState>();
  const [user, setUser] = useState<any | undefined>();

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return (
    <Router history={history}>
      {authState === AuthState.SignedIn && user ? (
        <Routes />
      ) : (
        <AmplifyAuthenticator
          usernameAlias="email"
          title="Pasquill-Clough Dashboard"
        >
          <AmplifySignIn
            headerText="Sign In to Dashboard"
            slot="sign-in"
            hideSignUp
            usernameAlias="email"
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      )}
    </Router>
  );
}
